.sessions-container {
  margin: 12px;
}

.sessions-windows-list {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1200px) {
  .sessions-windows-list {
    flex-direction: column;
  }
}
