.uri-add-container {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1200px) {
  .uri-add-container {
    flex-direction: column;
  }
}

.uri-add-header {
  display: flex;
  flex-direction: column;
}
