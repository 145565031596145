@import './vars.scss';
@import './material.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Helvetica', sans-serif;
  // font-size: 14px;
  /* transitions */
  /* transition: all 1s; */
  transition-timing-function: cubic-bezier(0.890, 0.045, 0.000, 0.925);
}
body {
  /* transitions */
  // transition: all 1s;
}

div {
  // transition: all 1s;
}


button {
  transition: all  1s;
  transition: width 0.4s;
}

button:disabled {
  cursor: not-allowed;
}

input:disabled {
  cursor: not-allowed;
}


pre {
  overflow-x: scroll;
  line-height: 18px;
}
code {
  white-space: pre-wrap;
  word-break: break-all;
}
