@import '../../vars.scss';

$nav-tab-height: $app-nav-height + $app-tab-height;

.app {

}

.app-routes {
  position: absolute;
  width: 100%;
  background-color: $app-background-color;

  &__without-tabs {
    top: $app-nav-height;
    min-height: calc(100vh - #{$app-nav-height});
  }

  &__with-tabs {
    top: $nav-tab-height;
    min-height: calc(100vh - #{$nav-tab-height});
  }
}

.home-div {
  margin: 12px;
}

.navigation-ul > li {
  display: inline;
  padding-left: 10px;
}

.delete-button {
  float: right;
}

/* Util */
.border-highlight {
  border: 3px solid red;
  border-radius: 10px;
  padding: 12px;
}

.fade {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity:1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity:1;
  }
}

.global-fab {
  position: fixed !important;
  right: 18px;
  bottom: 18px;
  z-index: 9999;
}

$bottom-drawer-padding: 4vw;

.bottom-drawer {
  position: fixed;
  bottom: 0;
  left: $bottom-drawer-padding;
  height: 0;
  width: calc(100vw - #{$bottom-drawer-padding * 2});
  background-color: white;
  transition: all 0.25s;
  transition-timing-function: $ease-in;

  &__expanded {
    height: 90vh;
    z-index: 999;
    transition: all 0.2s;
    transition-timing-function: $ease-out;
  }
}
