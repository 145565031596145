.tab-groups {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin: 8px;

  &__container {

  }

  &__header {

  }

  &__item {
    margin: 8px;
    min-width: 43vw;
    max-width: 43vw;
    height: 100%;
  }

}

.tab-image {
  width: 100%;
}

.tab-groups--cards {

  .group-title {



    &__edit {
      width: 100%;
    }

    &__edit-field {
      min-width: 60vw;
    }

  }

  .group-title-text {
    cursor: pointer;
  }

  .group-title-date {
    float: left;
    font-style: italic;
  }

}


@media only screen and (min-width: 870px) {
  .tab-groups {
    flex-direction: column;

    &__item {
      max-width: 90vw;
    }

    .tab-image {
      width: 100%;
      min-width: 500px;
      max-width: 500px; // Avoid overflow of list in card
    }
  }
}


@media only screen and (min-width: 1100px) {
  .tab-groups {
    flex-direction: row;

    &__item {
      // min-width: 28vw;
      // max-width: 28vw;
    }
  }
}


@media only screen and (min-width: 1300px) {
  .tab-groups {
    flex-direction: row;

    &__item {
      // min-width: 20vw;
      // max-width: 20vw;
    }
  }
}
