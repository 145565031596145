.uri-breakdown {

}

.uri-breakdown-body {
  display: flex;
  flex-direction: row;
}

.uri-breakdown-body__item {
  padding: 1rem;
}
