
.tab-elem {
  display: flex;
  width: 95%;
  padding: 4px;
  margin: 4px;
}

.tab-elem a {
  display: flex;
  align-content: space-between;
}

.tab-title {
  margin: 2px;
  font-size: 18px;
}

.tab-elem-pinned {
  display: inline-block;
  margin: 6px;
}

.tab-favicon {

}
