.navigation-container {

}

.navigation-title {

}

.navigation-title__clickable {
  cursor: pointer;
}

.navigation-tabs {
  margin-top: 17px;
}
