@import '../../vars.scss';

.tag-page {
  // position: absolute;
}

.tag-grid-tile {
  // display: block;
  // position: relative;
  // width: 100px;
  // height: 80px;
}


.tag-item {


  &-container{
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;

    .mdc-grid-tile__secondary {
      left: 0px;
      width: 100% !important;
    }

    .tag-uids {
      font-size: 8px;
      display: flex;
      overflow: scroll;

      &__item {

      }
    }

  }

  &__box {
    position: absolute;
    overflow: hidden;
    background-color: white;
    z-index: 999;
    transition: all 0.3s;
    transition-timing-function: $ease-move;
    cursor: pointer;
    margin: 0 !important;
    padding: 0 !important;
    max-width: 100%;
  }

  &__expanded {
    width: 100vw !important;
    height: 100vh !important;
    transition: all 0.25s;
    transition-timing-function: $ease-in;
  }
}
