
.window-elem {
  width: auto;
  padding: 10px;
  margin: 4px;
}

.window-elem a {
  /* display: flex; */
  /* align-content: space-between; */
}

.window-title {
  margin: 16px;
}

.window-favicon {
  /* padding: 6px; */
}

.window-elem__actions {
  display: flex;
  align-items: stretch !important;
}

.window-elem__actions .delete-button {
  align-self: flex-end;
}

.window-tabs-pinned {
  margin: 16px;
}
