.tab-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .mdc-card {
    margin: 16px;
    padding: 0px;
    width: 96vw;
  }

  &__container {
    width: 100%;
    padding: 6px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    cursor: pointer;

    .group-title {
      align-self: flex-start;
    }

     &__expand {
       margin-left: auto;
       align-self: flex-end;
     }
  }

}


@media only screen and (min-width: 870px) {

  .tab-groups--cards {
    .tab-cards {
      .mdc-card {
        width: 44vw;
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  .tab-groups--cards {
    .tab-cards {
      .mdc-card {
        width: 28vw;
      }
    }
  }

}


@media only screen and (min-width: 1300px) {
  .tab-groups--cards {
    .tab-cards {
      .mdc-card {
        width: 20vw;
      }
    }
  }
}
