$blue-color: lightblue;
$green-color: darkgreen;
$grey-color: lightgrey;

$primary-color: $blue-color;

$app-background-color: #e9ecf0;

$app-nav-height: 64px;
$app-tab-height: 64px;


// Animation
$ease-move: cubic-bezier(0.4, 0.0, 0.2, 1);
$ease-in: cubic-bezier(0.0, 0.0, 0.2, 1);
$ease-out: cubic-bezier(0.4, 0.0, 1, 1);
