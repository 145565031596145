/* List  */
.uri-list {
  font-size: 14px;
  background-color: white;
  padding: 6px;
}

.uri-domains {
  padding: 12px;
  overflow-x: auto;
  background-color: white;
}

.uri-domains:hover {
  background-color: lighten(lightgrey, 10);
  cursor: pointer;
}

.uri-paths {
  padding-left: 16px;
}

.uri-paths-group {
  padding-left: 12px;

  img {
    height: 200px;
  }
}


.uri-paths:hover {
  background-color: lighten(lightgrey, 10);

}


.uri-suffix {
  display: flex;
  background-color: white;
  padding: 6px;
  margin: 8px;
}

.uri-suffix-link {
  display: flex;
  align-content: space-between;
}

.uri-suffix-title {
  padding: 12px;
}

.uri-suffix img {
  height: 30px;
  padding: 6px;
  width: auto;
}
