/* RMWC Global Overrides */

.mdc-card {
  margin: 16px;
  padding: 12px;
}

.mdc-button {
  margin: 8px;
}
